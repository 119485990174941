import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <header className="header">
            <div className="logo-header">
                <img src="/images/Aylix Logo (30).png" alt="Logo" />
            </div>
            <nav className="header-nav">
                <ul>
                    <li><button className="header-nav-btn">Home</button></li>
                    <li><button className="header-nav-btn">About</button></li>
                    <li>
                        <div className="log-button-container">
                            <button className="header-login-btn">Login</button>
                        </div>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
